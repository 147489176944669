.enterCode {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.enterCode__card {
    padding: 69px;
    background: #FFFFFF;
    border-radius: 10px;
    width: 605px;
}

.enterCode__card__title {
    margin-bottom: 54px;
    font-weight: bold;
    font-size: 32px;
    line-height: 44px;
}

.enterCode__card__input {
    font-family: 'Roboto Mono', monospace !important;
    border: 3px solid #9077F5 !important;
    border-radius: 10px;
    height: 100px;
    font-size: 64px;
    font-weight: bold;
    letter-spacing: 22px;
    padding-left: 20px !important;
    margin-bottom: 16px;
    position: relative;
    z-index: 2;
    color: #C4C4C4;
    background: none !important;
    text-transform: uppercase;
}

.enterCode__card__inputWrap {
    position: relative;
}

.enterCode__card__inputPlaces {
    position: absolute;
    z-index: 1;
    bottom: 30px;
    left: 25px;
    color: #C4C4C4;
    font-size: 70px;
    line-height: 60px;
    letter-spacing: 0.05em;
}

.enterCode__card__joined {
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;
    display: block;
    margin-bottom: 9px;
}

.enterCode__card__group {
    font-weight: bold;
    font-size: 28px;
    line-height: 34px;
    display: block;
    margin-bottom: 63px;
}


.enterCode__text {
    font-size: 16px;
    line-height: 22px;
    margin-top: 44px;
    max-width: 466px;
}

.enterCode__text a {
    color: #1F1F1F !important;
    text-decoration: underline;
}


@media screen and (max-width: 700px) {
    .enterCode {
        width: 100%;
    }

    .joinPage__content {
        flex-direction: column-reverse;
        align-items: center;
    }

    .enterCode__card {
        width: 400px;
        padding: 20px;
    }

    .enterCode__text {
        max-width: 400px;
    }

    .enterCode__card__inputPlaces {
        font-size: 50px;
    }

    .enterCode__card__input {
        font-size: 50px;
        letter-spacing: 13px;
    }
}

@media screen and (max-width: 500px) {
    .enterCode__card {
        width: 300px;
    }

    .enterCode__text {
        max-width: 300px;
    }

    .enterCode__card__inputPlaces {
        font-size: 36px;
    }

    .enterCode__card__input {
        font-size: 36px;
        letter-spacing: 10px;
    }
}
