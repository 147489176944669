.antd-confirm-warning-modal .ant-modal-body {
  background-color: rgba(194, 13, 13, 0.7);
  animation-name: redAtention;
  animation-iteration-count: infinite;
  animation-duration: .8s;
  animation-direction: alternate;
}

@keyframes redAtention {
  0% {
    box-shadow: 0 0 10px 0px rgba(114, 65, 65, 0.7);
  }
  100% {
    box-shadow: 0 0 20px 10px rgba(194, 13, 13, 0.7);
  }
}