@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap);
html {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

body {
  margin: 0;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F6F8F9;
  color: #1F1F1F;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: bold;
}

.grecaptcha-badge {
    display:block;
}
.mock__bar {
  fill: #9077f5;
}
#mock__chart {
  width: 100vw;
  height: 100vh;
  border: 1px solid black;
}

.mock__text {
  font-family: Montserrat;
  font-size: 15px;
  line-height: 26px;
  /* identical to box height, or 130% */
  text-align: right;

  /* Typography / Primary */
  color: #1f1f1f;
  text-anchor: end;
}

.mock__line {
  stroke: #c4c4c4;
  stroke-width: 2px;
}

.mock__top-line {
  stroke: #c4c4c4;
}

.mock__vertical-grid__path {
  stroke: #c4c4c4;
}

.mock__vertical-grid__path_50 {
  stroke-width: 2px;
}

.mock__vertical-grid__text {
  font-family: Montserrat;
  font-size: 15px;
  line-height: 26px;
  color: #1f1f1f;

  text-anchor: middle;
}


.App {
  background-color: white;
  text-align: center;
  height: 100vh;
  font-family: "Montserrat";
}

.header {
  margin-top: 0px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.x-axis {
  stroke-width: 0;
}

.tick {
 font-weight: 500;
  font-size: 13px;
  line-height: 16px;  
}

.y-axis {
  stroke-width: 0;
}

.axis line {
  stroke: #ffcfb4;
}

.axis path {
  stroke-width: 0;
}

.lineFill {
  fill : url(#gradient)
}

.linePoint * {
  opacity: 0;
}

.linePoint-point {
  cursor: pointer;
}

.linePoint:hover * {
  opacity: 1;
}



.lineTarget:hover .lineTarget-text {
  opacity: 1;
}

.linePoint-point {
  opacity: 1;
}

.linePoint-text {
  text-anchor: middle;
  font-size: 13px;
  font-weight: 700;
}

.linePoint-dateLine {
  stroke: #C7BBFA;
  stroke-width: 2px;
  stroke-dasharray: 4;
}

.lineTarget-line {
  stroke: #9077F5;
  stroke-width: 3px;
}

.lineTarget-text {
  opacity: 0;
  font-weight: bold;
  font-size: 12px;
  line-height: 17px;
}


.pie-value {
  text-anchor: middle;
  font-weight: bold;
  font-size: 21.129px;
  line-height: 21px;
}
.enterCode {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.enterCode__card {
    padding: 69px;
    background: #FFFFFF;
    border-radius: 10px;
    width: 605px;
}

.enterCode__card__title {
    margin-bottom: 54px;
    font-weight: bold;
    font-size: 32px;
    line-height: 44px;
}

.enterCode__card__input {
    font-family: 'Roboto Mono', monospace !important;
    border: 3px solid #9077F5 !important;
    border-radius: 10px;
    height: 100px;
    font-size: 64px;
    font-weight: bold;
    letter-spacing: 22px;
    padding-left: 20px !important;
    margin-bottom: 16px;
    position: relative;
    z-index: 2;
    color: #C4C4C4;
    background: none !important;
    text-transform: uppercase;
}

.enterCode__card__inputWrap {
    position: relative;
}

.enterCode__card__inputPlaces {
    position: absolute;
    z-index: 1;
    bottom: 30px;
    left: 25px;
    color: #C4C4C4;
    font-size: 70px;
    line-height: 60px;
    letter-spacing: 0.05em;
}

.enterCode__card__joined {
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;
    display: block;
    margin-bottom: 9px;
}

.enterCode__card__group {
    font-weight: bold;
    font-size: 28px;
    line-height: 34px;
    display: block;
    margin-bottom: 63px;
}


.enterCode__text {
    font-size: 16px;
    line-height: 22px;
    margin-top: 44px;
    max-width: 466px;
}

.enterCode__text a {
    color: #1F1F1F !important;
    text-decoration: underline;
}


@media screen and (max-width: 700px) {
    .enterCode {
        width: 100%;
    }

    .joinPage__content {
        flex-direction: column-reverse;
        align-items: center;
    }

    .enterCode__card {
        width: 400px;
        padding: 20px;
    }

    .enterCode__text {
        max-width: 400px;
    }

    .enterCode__card__inputPlaces {
        font-size: 50px;
    }

    .enterCode__card__input {
        font-size: 50px;
        letter-spacing: 13px;
    }
}

@media screen and (max-width: 500px) {
    .enterCode__card {
        width: 300px;
    }

    .enterCode__text {
        max-width: 300px;
    }

    .enterCode__card__inputPlaces {
        font-size: 36px;
    }

    .enterCode__card__input {
        font-size: 36px;
        letter-spacing: 10px;
    }
}

.joinPage__container {
    margin: 0 auto;
    width: 95%;
    max-width: 1200px;
}

.joinPage__logo {
    margin: 29px auto 120px auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: block;
}


.joinPage__title {
    font-weight: bold;
    font-size: 50px;
    line-height: 50px;
    text-align: center;
    margin-bottom: 75px;
}


.joinPage__title .accent {
    color: #F87700;
}


.joinPage__content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

@media screen and (max-width: 1200px) {
    .joinPage__content {
        flex-direction: column-reverse;
        align-items: center;
    }
}

@media screen and (max-width: 700px) {
    .joinPage__image {
        width: 100%;
    }
    .joinPage__logo {
        margin-bottom: 50px;
    }
    .joinPage__title {
        font-size: 36px;
    }
}



.antd-confirm-warning-modal .ant-modal-body {
  background-color: rgba(194, 13, 13, 0.7);
  animation-name: redAtention;
  animation-iteration-count: infinite;
  animation-duration: .8s;
  animation-direction: alternate;
}

@keyframes redAtention {
  0% {
    box-shadow: 0 0 10px 0px rgba(114, 65, 65, 0.7);
  }
  100% {
    box-shadow: 0 0 20px 10px rgba(194, 13, 13, 0.7);
  }
}
