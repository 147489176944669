.joinPage__container {
    margin: 0 auto;
    width: 95%;
    max-width: 1200px;
}

.joinPage__logo {
    margin: 29px auto 120px auto;
    width: fit-content;
    display: block;
}


.joinPage__title {
    font-weight: bold;
    font-size: 50px;
    line-height: 50px;
    text-align: center;
    margin-bottom: 75px;
}


.joinPage__title .accent {
    color: #F87700;
}


.joinPage__content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

@media screen and (max-width: 1200px) {
    .joinPage__content {
        flex-direction: column-reverse;
        align-items: center;
    }
}

@media screen and (max-width: 700px) {
    .joinPage__image {
        width: 100%;
    }
    .joinPage__logo {
        margin-bottom: 50px;
    }
    .joinPage__title {
        font-size: 36px;
    }
}


